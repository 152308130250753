var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "route" },
    [
      _vm.routeComponent === "landingPage"
        ? _c("landing-page", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "multipleSteps"
        ? _c("multiple-steps", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "multipleStepsAddCoApplicant"
        ? _c("multiple-steps-add-co-applicant", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "multipleStepsContinueApplication"
        ? _c("multiple-steps-continue-application", {
            on: { loaded: _vm.loaded },
          })
        : _vm.routeComponent === "singleStep"
        ? _c("single-step", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "singleStepCalculator"
        ? _c("single-step-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "creditCard"
        ? _c("credit-card", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "singleStepAddCoApplicant"
        ? _c("single-step-add-co-applicant", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "singleStepContinueApplication"
        ? _c("single-step-continue-application", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "whiteLabelApplication"
        ? _c("white-label-application", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "bluestep"
        ? _c("bluestep", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "optOut"
        ? _c("opt-out", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "optOutAutoSbu"
        ? _c("opt-out-auto-sbu", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "loanIndicator"
        ? _c("loan-indicator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "loanCalculator"
        ? _c("loan-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "affiliateSignup"
        ? _c("affiliate-signup", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "calculator"
        ? _c("calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "bookTime"
        ? _c("book-time", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "addLead"
        ? _c("add-lead", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "multipleStepsPv"
        ? _c("multiple-steps-pv", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "singleStepPv"
        ? _c("single-step-pv", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "smeForm"
        ? _c("sme-form", { on: { loaded: _vm.loaded } })
        : _vm._e(),
      _vm._v(" "),
      _vm.routeComponent === "smeSingleStep"
        ? _c("sme-single-step", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "mortgageTypeCalculator"
        ? _c("mortgage-type-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "increaseMortgageCalculator"
        ? _c("increase-mortgage-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "loanCommitmentCalculator"
        ? _c("loan-commitment-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "newMortgageCalculator"
        ? _c("new-mortgage-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "transferMortgageCalculator"
        ? _c("transfer-mortgage-calculator", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "calculatorMaxLoanAmount"
        ? _c("calculator-max-loan-amount", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "loanCommitment"
        ? _c("loan-commitment", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "transferMortgage" ||
          _vm.routeComponent === "increaseMortgage"
        ? _c("transfer-mortgage", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "newMortgage"
        ? _c("new-mortgage", { on: { loaded: _vm.loaded } })
        : _vm.routeComponent === "zipCodeForm"
        ? _c("zip-code-form", { on: { loaded: _vm.loaded } })
        : _vm._e(),
      _vm._v(" "),
      _vm.routeComponent === "insuranceCompanyTeaser"
        ? _c("insurance-company-teaser", { on: { loaded: _vm.loaded } })
        : _vm._e(),
      _vm._v(" "),
      _vm.routeComponent === "insuranceCompanyCarTeaser"
        ? _c("insurance-company-car-teaser", { on: { loaded: _vm.loaded } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }